import { detectAppLanguage, setLngToLocalStore } from '../../utils/language';


export const SET_LANGUAGE = 'localize/SET_LANGUAGE';

window.language = detectAppLanguage();

const initialState = {
	language: window.language
};

export default function reducer(state = initialState, action = {}) {
	switch (action.type) {
		case SET_LANGUAGE:
			if (action.oldLanguage !== action.newLanguage) {
				window.language = action.newLanguage;
				setLngToLocalStore(action.newLanguage);
				return { ...state, language: action.newLanguage };
			}
			else {
				return state;
			}

		default:
			return state;
	}
}