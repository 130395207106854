import { createUserManager } from 'redux-oidc';
import { WebStorageStateStore } from 'oidc-client';

import { Constants } from '../helpers/Constants';

const userManagerConfig = {
    authority: Constants.stsAuthority,
    client_id: Constants.clientId,
    redirect_uri: `${Constants.clientRoot}callback`,
    silent_redirect_uri: `${Constants.clientRoot}silent-renew.html`,
    post_logout_redirect_uri: `${Constants.clientRoot}`,
    response_type: 'id_token token',
    scope: Constants.clientScope,
    ui_locales: 'xx',

    automaticSilentRenew: true,
    filterProtocolClaims: true,
    loadUserInfo: true,

    // metadata: {
    //     issuer: Constants.stsAuthority,
    //     jwks_uri: Constants.stsAuthority + "/.well-known/openid-configuration/jwks",
    //     end_session_endpoint: Constants.stsAuthority + "/connect/endsession",
    //     authorization_endpoint: Constants.stsAuthority + "/connect/authorize",
    //     userinfo_endpoint: Constants.stsAuthority + "/connect/userinfo",
    // },
    // signingKeys: [
    //     {
    //         kty: "RSA",
    //         use: "sig",
    //         kid: "138774e80e756b2ab9fee4d7c2c5668c",
    //         e: "AQAB",
    //         n: "1CG5-pIC-aRQ0GYVFwZytluyylH9n9PCSk6TZghF7mkfgT3bch__y7yl8WrlGI58Jt3oSRxE12sYTKEwM1TAUyyJDsngaYjxipLu5wSELb4nez1qFCYCGzEMezP6rOhC7vHhfdKpc1DuEV-JjSJ3u3luUK13s74aVyu_V4_QLE_jDq1rgFv462eTI2r2kqIp10UmQbdT-wU_trxNoKwydTB7YX3pCZRjk8M6cwn37Vy4GZY596mg4BdCfr4Yn-MST1V5-E77vZ4Q4X7xNIsD7fiFt2UlyrTRnQTmUOLpFW08EVrewXg4cQzfc5FkIoTyL-A9UAyuhEB9HdyRq7IGcQ",
    //         alg: "RS256"
    //     }
    // ],

    userStore: new WebStorageStateStore({ store: window.localStorage })
};

const userManager = createUserManager(userManagerConfig);

export default userManager;