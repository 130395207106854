import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';
import classnames from 'classnames';
import { hasClass, addClass, removeClass } from '../utils/class';


require('./modal.scss');

class Modal extends Component {
	static propTypes = {
		show: PropTypes.bool,
		buttons: PropTypes.element,
		onClose: PropTypes.func,
		onSubmit: PropTypes.func,
		type: PropTypes.string,
		pending: PropTypes.bool,
		title: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.element
		]),
		modalClass: PropTypes.string,
		closeOnEsc: PropTypes.bool,
		showCloseButton: PropTypes.bool,
		closeOnClick: PropTypes.bool
	};

	static defaultProps = {
		show: true,
		showCloseButton: false,
		closeOnClick: false
	};

	static contextTypes = {
		// translate: PropTypes.func.isRequired
	};

	modalWindowWidth = null;
	modalWindowHeight = null;

	intervalReferencer = null;

	documentHadNoScrollClass = false;

	constructor(props) {
		super(props);

		this.submit = this.submit.bind(this);
		this.onEscClose = this.onEscClose.bind(this);
		this.onResizeModal = this.onResizeModal.bind(this);
	}

	componentWillMount() {
		if (hasClass(document.body.className, 'noscroll')) {
			this.documentHadNoScrollClass = true;
		}
	}

	componentDidMount() {
		let _self = this;

		if (this.props.show && !hasClass(document.body.className, 'noscroll')) {
			document.body.className = addClass(document.body.className, 'noscroll');
		}

		document.addEventListener('keydown', this.onEscClose, false);
		window.addEventListener('resize', this.onResizeModal, false);

		this.onResizeModal();

		let modalWindow = findDOMNode(this.refs.modalWindow);
		this.modalWindowHeight = modalWindow.offsetHeight;
		this.modalWindowWidth = modalWindow.offsetWidth;

		this.intervalReferencer = setInterval(() => {
			let modalWindow = findDOMNode(this.refs.modalWindow);
			let modalWindowHeight = modalWindow.offsetHeight;
			let modalWindowWidth = modalWindow.offsetWidth;

			if (_self.modalWindowHeight !== modalWindowHeight || _self.modalWindowWidth !== modalWindowWidth) {
				_self.modalWindowHeight = modalWindowHeight;
				_self.modalWindowWidth = modalWindowWidth;

				_self.onResizeModal();
			}
		}, 200);
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.show !== nextProps.show) {
			if (nextProps.show && !hasClass(document.body.className, 'noscroll')) {
				document.body.className = addClass(document.body.className, 'noscroll');
			} else {
				document.body.className = removeClass(document.body.className, 'noscroll');
			}
		}
	}

	componentWillUnmount() {
		clearInterval(this.intervalReferencer);

		if (!this.documentHadNoScrollClass) {
			document.body.className = removeClass(document.body.className, 'noscroll');
		}

		document.removeEventListener('keydown', this.onEscClose);
		window.removeEventListener('resize', this.onResizeModal);
	}

	onResizeModal() {
		let modalWindow = findDOMNode(this.refs.modalWindow);
		let modal = findDOMNode(this.refs.modal);
		let height = modalWindow.offsetHeight;

		if (window.innerHeight - 20 < height) {
			modal.className = addClass(modal.className, 'align-to-top');
		} else {
			modal.className = removeClass(modal.className, 'align-to-top');
		}
	}

	onEscClose(evt) {
		evt = evt || window.event;
		if (evt.keyCode === 27 && this.props.onClose && (this.props.closeOnEsc !== false)) {
			this.props.onClose();
		}
	}

	submit(e) {
		e.preventDefault();
		if (this.props.onSubmit) {
			this.props.onSubmit();
		}
		return;
	}

	onClickOutside() {
		if (this.props.closeOnClick) {
			this.props.onClose();
		}
	}

	onClickInside(e) {
		if (this.props.closeOnClick) {
			e.stopPropagation();
		}
	}

	renderContent() {
		return (
			<>
				<div className="modal-window-header">
					{this.props.title && <h4 dangerouslySetInnerHTML={{ __html: this.props.title }}></h4>}
					{this.props.showCloseButton &&
						<button
							className="close-button"
							onClick={this.props.onClose}>
							<i className="fa fa-times" />
						</button>
					}
				</div>

				<div className="modal-window-content">{this.props.children && this.props.children}</div>

				{this.props.buttons && <div className="modal-window-footer">{this.props.buttons}</div>}
			</>
		);
	}

	render() {
		let isForm = this.props.type === 'form';
		let classes = this.props.modalClass || '';

		return (
			<div
				className={classnames('modal', classes, { 'show': this.props.show })}
				ref="modal"
				onClick={() => this.onClickOutside()}
			>
				{isForm &&
					<form
						ref="modalWindow"
						className="modal-window"
						onSubmit={this.submit}
						onClick={e => this.onClickInside(e)}
					>
						{this.renderContent()}
					</form>
				}
				{!isForm &&
					<div
						ref="modalWindow"
						className="modal-window"
						onSubmit={this.submit}
						onClick={e => this.onClickInside(e)}
					>
						{this.renderContent()}
					</div>
				}
			</div>
		);
	}
};

export default Modal;
