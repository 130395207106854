import { PROFILE_PENDING, PROFILE_LOADED, PROFILE_ERROR } from '../stores/profileStore'
import { ApiService } from '../../services/ApiService';

export function loadProfile() {
    return (dispatch, getState) => {
        // console.log('loading profile...');
        dispatch({ type: PROFILE_PENDING });
        let api = new ApiService();
        api.getProfile()
            .then(response => {
                if (response.status === 204) {
                    let { sub, name, preferred_username } = getState().oidc.user.profile;
                    // console.log('create profile', getState().oidc.user);
                    api.createProfile(sub, name, preferred_username)
                        .then(createResponse => {
                            dispatch({ type: PROFILE_LOADED, data: createResponse.data });
                        })
                        .catch(error => {
                            console.log('create profile error', error);
                            dispatch({ type: PROFILE_ERROR, error });
                        })
                } else {
                    dispatch({ type: PROFILE_LOADED, data: response.data });
                }
            })
            .catch(error => {
                console.log('loadProfile error', error);
                dispatch({ type: PROFILE_ERROR, error });
            })
    }
}