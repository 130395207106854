import reduxThunk from 'redux-thunk';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import { reducer as oidcReducer, loadUser } from 'redux-oidc';
import { connectRouter, routerMiddleware } from 'connected-react-router'

import profile from './stores/profileStore';
import localize from './stores/localizeStore';
import userManager from '../utils/userManager';

function createStoreFnc(history){
	const routerHistoryMiddleware = routerMiddleware(history);

	const middlewares = [reduxThunk, routerHistoryMiddleware];

	const store = createStore(
		combineReducers({
			profile,
			localize,
            router: connectRouter(history),
            oidc: oidcReducer
			// intl: intlReducer
		}),
		{
			// intl: {
			// 	locale: 'cs'
			// }
		},
		applyMiddleware(...middlewares)
	)
	loadUser(store, userManager);

	return store;
}

export default createStoreFnc;