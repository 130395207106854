import _ from 'lodash';
import * as React from 'react';
import * as toastr from 'toastr';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { parse } from 'qs';
import { push } from "connected-react-router";

import Modal from '../../../components/Modal';
import Period from '../../../components/Period';
import { ApiService } from '../../../services/ApiService';
import { toModalDate, toLunchShortDate, toDate, toPeriodDate, addDay, today, parseDate } from '../../../utils/date';
import { arraySort } from '../../../utils/sort';
import { getLangId } from '../../../utils/language';
import { MT_MENU1, MT_MENU3, MT_MENU2, MT_SNACK, MEAL_TYPES } from '../../../helpers/Constants';

import './lunchMenu.scss';
import { initPeriod, toPeriod, nextPeriod } from '../../../utils/period';

const initOrders = {
    data: {},
    pending: false,
    error: null
};
const HIDE_PAST_KEY = 'lm_hidepast';
const NEXT_PERIOD_LIMIT = 25;

class LunchMenu extends React.Component {
    static propTypes = {
        language: PropTypes.string.isRequired,
        profile: PropTypes.object.isRequired,
        location: PropTypes.object,
        push: PropTypes.func.isRequired
    }

    static contextTypes = {
        translate: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.api = new ApiService();
        this.query = parse(props.location.search.substr(1));

        let getPeriod = () => {
            let current = initPeriod();
            if (this.query.period) {
                let requested = toPeriod(new Date(parseDate(this.query.period)));
                props.push(props.location.path);
                if (requested
                    && (addDay(current, NEXT_PERIOD_LIMIT).getTime() <= new Date().getTime())
                    && requested.getTime() === nextPeriod(current).getTime()) {
                    return requested;
                }
            }
            return current;
        };
        this.state = {
            period: getPeriod(),
            menu: {
                data: {},
                pending: false,
                loadedAt: null,
                error: null
            },
            diners: {
                data: {},
                pending: false,
                loaded: false,
                error: null,
                self: null,
                selected: ''
            },
            orders: initOrders,
            settings: {
                data: {},
                pending: false,
                loaded: false
            },
            modal: {
                open: false
            },
            alergens: {},
            hideNoDinerInfo: false,
            hideMissingBillingInfo: false,
            hidePast: (localStorage.getItem(HIDE_PAST_KEY) === 'true') || false
        }
        this.mounted = false;

        this.monthNames = [
            "January", "February", "March",
            "April", "May", "June", "July",
            "August", "September", "October",
            "November", "December"
        ];
    }

    componentDidMount() {
        this.mounted = true;
        this.loadMenu();
        this.loadAlergens();
        if (this.props.profile.loaded) {
            this.loadDiners();
            this.loadOrders();
            this.loadSettings();
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.profile.loaded && !this.props.profile.loaded) {
            this.loadDiners();
            this.loadOrders();
            this.loadSettings();
        }
    }

    loadAlergens() {
        this.api.getAlergens()
            .then(response => {
                if (this.mounted) {
                    let alergens = _.mapKeys(response.data, 'id');
                    this.setState({ alergens });
                }
            })
            .catch(error => {
                console.log('LunchMenu.loadAlergens error', error);
            });
    }

    loadSettings() {
        let { settings } = this.state;
        this.setState({ settings: { ...settings, pending: true } });
        this.api.getSettings()
            .then(response => {
                if (this.mounted) {
                    this.setState({
                        settings: {
                            data: response.data,
                            pending: false,
                            loaded: true
                        }
                    });
                }
            })
            .catch(error => {
                console.log('LunchMenu.loadSettings error', error);
                if (this.mounted) {
                    this.setState({
                        settings: {
                            ...settings,
                            pending: false
                        }
                    });
                }
            });
    }

    loadDiners() {
        this.setState({ diners: { ...this.state.diners, pending: true } });
        this.api.getAccountDiners()
            .then(response => {
                if (this.mounted) {
                    let data = _.mapKeys(response.data, 'id');
                    let self = _.find(response.data, { isUser: true });
                    let selected = self ? self.id : (Object.keys(data).length ? Object.keys(data)[0] : '');
                    this.setState({
                        diners: {
                            ...this.state.diners,
                            pending: false,
                            loaded: true,
                            data,
                            self,
                            selected
                        }
                    }, () => {
                        // console.log('LunchMenu.loadDiners diners loaded', this.state.diners);
                        this.loadOrders();
                    });
                }
            })
            .catch(error => {
                console.log('LunchMenu.loadDiners error', error);
                if (this.mounted) {
                    this.setState({
                        menu: {
                            ...this.state.diners,
                            pending: false,
                            error: error
                        }
                    });
                }
            })
    }

    loadOrders() {
        if (!this.state.diners.selected) {
            console.log('Orders not loaded because no selected diner.');
            return;
        }

        this.setState({ orders: { ...this.state.orders, pending: true } });
        this.api.getOrders(this.state.period, this.state.diners.selected)
            .then(response => {
                if (this.mounted) {
                    // console.log(_.mapKeys(response.data, o => parseDate(o.date)));
                    this.setState({
                        orders: {
                            ...this.state.orders,
                            pending: false,
                            data: _.mapKeys(response.data, o => parseDate(o.date)),
                            error: null
                        }
                    });
                }
            })
            .catch(error => {
                console.log('LunchMenu.loadOrders error', error);
                if (this.mounted) {
                    this.setState({
                        orders: {
                            ...this.state.orders,
                            pending: false,
                            error: error
                        }
                    });
                    toastr.error(error);
                }
            })
    }

    loadMenu() {
        this.setState({ menu: { ...this.state.menu, pending: true } });
        this.api.getMenu(this.state.period)
            .then(data => {
                if (this.mounted) {
                    this.setState({
                        menu: {
                            ...this.state.menu,
                            pending: false,
                            data: this.transformData(data),
                            loadedAt: new Date(),
                            error: null
                        }
                    });
                }
            })
            .catch(error => {
                console.log('LunchMenu.loadMenu error', error);
                if (this.mounted) {
                    this.setState({
                        menu: {
                            ...this.state.menu,
                            pending: false,
                            error: error
                        }
                    });
                    toastr.error(error);
                }
            })
    }

    transformData(data) {
        let result = (data.data || []).reduce((r, o) => {
            let time = parseDate(o.date);
            r[time] = r[time] || {};
            r[time][o.lang] = o;
            return r;
        }, {});
        // console.log('LunchMenu.transformData', { data: data.data, result });
        return result;
    }

    nextPeriod(value) {
        return new Date(value.getFullYear(), value.getMonth() + 1, 1);
    }

    nextDay(value) {
        return new Date(value.getFullYear(), value.getMonth(), value.getDate() + 1);
    }

    onPeriodChange(period) {
        this.setState({ period }, () => {
            this.loadMenu();
            this.loadOrders();
        });
    }

    setOrder(time, mealType) {
        this.initMissingOrders([time], () => {
            this.setMeals([{ time, mealType }]);
        });
    }

    setMealCol(columns, mealType) {
        let times = columns[mealType].times;
        this.initMissingOrders(times, () => {
            this.setMeals(times.map(o => ({ time: o, mealType })), !columns[mealType].checked);
        });
    }

    initMissingOrders(times, next) {
        let newItems = {};
        times.forEach(o => {
            if (!(o in this.state.orders.data)) {
                newItems[o] = {
                    date: toModalDate(o),
                    dinerId: this.state.diners.selected,
                    menu: 0,
                    snack: false
                };
            }
        });
        if (Object.keys(newItems).length) {
            this.setState({ orders: { ...this.state.orders, data: { ...this.state.orders.data, ...newItems } } },
                () => next());
        } else {
            next();
        }
    }

    setMeals(meals, check) {
        let validationResult = this.balanceChangeValidation(meals, check);
        if (validationResult.success) {
            let diners = {
                ...this.state.diners,
                data: { ...this.state.diners.data }
            };
            diners.data[this.state.diners.selected] = { ...diners.data[this.state.diners.selected], unpostedOrders: validationResult.unposted }
            let orders = { ...this.state.orders, data: { ...this.state.orders.data, ...validationResult.orders } };
            this.setState({ orders, diners }, () => {
                this.api.setOrders(this.state.diners.selected, _.map(validationResult.orders))
                    .catch(error => {
                        console.log('LunchMenu.setMeals error setOrders', error);
                        if (this.mounted) {
                            toastr.error(error);
                            this.loadOrders();
                        }
                    });
            });
        } else {
            toastr.error(this.context.translate('LunchMenu.LimitExceeded'));
        }
    }

    balanceChangeValidation(meals, check) {
        let { orders, diners, settings } = this.state;

        let isCheck = !(check === undefined || check === null);
        let selectedDiner = (diners.data || {})[diners.selected || 0] || {};

        let result = {
            success: false,
            orders: {},
            unposted: selectedDiner.unpostedOrders
        };

        meals.forEach(o => {
            let order = orders.data[o.time];

            if (o.mealType === MT_SNACK) {
                if (!isCheck || (isCheck && check !== order.snack)) {
                    result.orders[o.time] = { ...order, snack: !order.snack };
                    if (selectedDiner.free === false) {
                        result.unposted += this.getPrice(MT_SNACK) * (order.snack ? -1 : 1);
                    }
                }
            } else {
                if (order.menu === o.mealType && (!isCheck || (isCheck && !check))) {
                    result.orders[o.time] = { ...order, menu: 0 };
                    if (selectedDiner.free === false) {
                        result.unposted -= this.getPrice(order.menu);
                    }
                } else if (order.menu !== o.mealType && (!isCheck || (isCheck && check))) {
                    result.orders[o.time] = { ...order, menu: o.mealType };
                    if (selectedDiner.free === false) {
                        result.unposted -= this.getPrice(order.menu);
                        result.unposted += this.getPrice(o.mealType);
                    }
                }
            }
        });

        result.success = check === false || result.unposted < selectedDiner.unpostedOrders || selectedDiner.balance - result.unposted >= -settings.data.debitLimit;
        return result;
    }

    getPrice(mealType) {
        let { settings } = this.state;

        switch (mealType) {
            case MT_MENU1:
                return (settings.data || {}).menu1Price || 0;
            case MT_MENU2:
                return (settings.data || {}).menu2Price || 0;
            case MT_MENU3:
                return (settings.data || {}).menu3Price || 0;
            case MT_SNACK:
                return (settings.data || {}).snackPrice || 0;
            default:
                return 0;
        }
    }

    closeModal() {
        this.setState({ modal: { ...this.state.modal, open: false } });
    }

    selectedDinerChanged(diner) {
        let { diners } = this.state;
        this.setState({ diners: { ...diners, selected: diner }, orders: initOrders }, () => this.loadOrders());
    }

    getAlergens(algStr) {
        if (!algStr) {
            return this.context.translate('LunchMenu.NoAlergens');
        }

        var algs = this.parseAlergens(algStr);
        if (!algs.length) {
            return this.context.translate('LunchMenu.AlergensNotParsed');
        }
        let { alergens } = this.state;
        return algs.map(o => `${o} - ${(alergens[o] || {})[this.props.language || 'en']}`).join('\r\n');
    }

    parseAlergens(algStr) {
        // console.log('split', algStr, algStr.split(/[^\d]+/));
        return algStr.trim().split(/[^\d]+/);
    }

    export() {
        let { period } = this.state;
        // console.log('LunchMenu.export', { period, lang: this.props.language });
        this.api.exportMenuPdf(period, this.props.language)
            .then(response => {
                const url = window.URL.createObjectURL(response.data);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `menu-${toPeriodDate(period)}.pdf`);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            })
            .catch(error => {
                console.log('LunchMenu.export error', error);
            })
    }

    renderCell(canEdit, text, alg, order, time, item) {
        // console.log('LunchMenu.renderCell', { canEdit, text, alg, order, time, item });
        let tooltip = this.getAlergens(alg);
        let enabled = canEdit && text;
        let active = enabled && time > this.timeLimit;
        // console.log('LunchMenu.renderCell', { enabled, active, time, timeLimit: this.timeLimit });

        return (
            <td className={classnames({ active })}>
                <div className="ghost-content">
                    <div className="text-part">
                        <div className="menu-part">{text}</div>
                        <div className="alg-part">{alg}</div>
                    </div>
                    {enabled &&
                        <div className="check-part">

                        </div>
                    }
                </div>
                <div
                    className={classnames("menu-back", 'meal-' + item, { ordered: order })}
                >
                    <div
                        className={classnames("menu-cell", { ordered: order })}
                        onClick={() => { if (active) this.setOrder(time, item); }}
                    >
                        <div className="text-part">
                            <div className="menu-part">{text}</div>
                            <div
                                className="alg-part"
                                data-toggle="tooltip"
                                title={tooltip}
                            >{alg}</div>
                        </div>
                        {enabled &&
                            <div className="check-part">
                                <input
                                    type="checkbox"
                                    checked={order}
                                    onChange={() => { }}
                                    disabled={!active} />
                            </div>
                        }
                    </div>
                </div>
            </td>
        );
    }

    renderRow(data, menu3Enabled) {
        // console.log('LunchMenu.renderRow', { data });
        return (
            <tr key={toModalDate(data.date)}>
                <td>
                    <div className="date-cell">
                        <div className="date-part">
                            <div className="day">{this.context.translate(`Days.Short.${toDate(data.date).getDay()}`)}</div>
                            <div className="date">{toLunchShortDate(data.date)}</div>
                        </div>
                        <div className="alg-part">{this.context.translate('LunchMenu.Alergens')}</div>
                    </div>
                </td>
                {this.renderCell(false, data.soup, data.soupAlg)}
                {this.renderCell(data.canEdit, data.menu1, data.menu1Alg, data.menu1Value, data.time, MT_MENU1)}
                {this.renderCell(data.canEdit, data.menu2, data.menu2Alg, data.menu2Value, data.time, MT_MENU2)}
                {menu3Enabled ? this.renderCell(data.canEdit, data.menu3, data.menu3Alg, data.menu3Value, data.time, MT_MENU3) : <></>}
                {this.renderCell(data.canEdit, data.snack, data.snackAlg, data.snackValue, data.time, MT_SNACK)}
            </tr>
        );
    }

    renderMealHeader(columns, mealType) {
        return (
            <th>
                <div className="menu-header">
                    <div className="menu-header-title">{this.context.translate('MealType.' + mealType)}</div>
                    {columns[mealType].canCheck &&
                        <div
                            className="menu-header-check"
                            onClick={() => this.setMealCol(columns, mealType)}
                        >
                            <input
                                type="checkbox"
                                checked={columns[mealType].checked}
                                onChange={() => { }}
                                disabled={false} />
                        </div>
                    }
                </div>
            </th>
        );
    }

    render() {
        let { period, hideNoDinerInfo, hideMissingBillingInfo, settings, hidePast } = this.state;
        let { profile } = this.props;

        // Chybějící fakturační údaje
        let missingBillingInfo = profile.loaded && (!profile.data.address || !profile.data.accountNumber);
        let billingInfoIsOk = profile.loaded && !missingBillingInfo;
        let showMissingBillingInfo = missingBillingInfo && !hideMissingBillingInfo;

        // console.log('LunchMenu.render');
        let lngId = getLangId(this.props.language);
        this.timeLimit = new Date().getTime() + (12 * 60 * 60 * 1000);
        let nextPeriodTime = this.nextPeriod(period).getTime();
        let isDinerSelected = !!this.state.diners.selected;
        let showDinerInfo = isDinerSelected && billingInfoIsOk;
        let selectedDiner = null;
        let amount = 0;
        let menu3Enabled = false;
        if (isDinerSelected) {
            selectedDiner = this.state.diners.data[this.state.diners.selected];
            amount = selectedDiner.unpostedOrders;
            menu3Enabled = selectedDiner.menu3Enabled;
        }
        let isAllInclusiveDiner = selectedDiner && selectedDiner.free;

        let columns = _.mapKeys(MEAL_TYPES.map(o => ({
            type: o,
            checked: false,
            canCheck: false,
            canNotCheck: false,
            times: []
        })), 'type');

        let periodTime = period.getTime();
        let todayTime = today().getTime();
        let hidePastEnabled = todayTime >= periodTime && todayTime < nextPeriodTime;

        let days = [];
        for (let d = new Date(period.getTime()); d.getTime() < nextPeriodTime; d = this.nextDay(d)) {
            let time = d.getTime();
            if (hidePast && hidePastEnabled && time < todayTime) {
                continue;
            }

            // console.log('LunchMenu.render 1', { time, d, period, periodTime: period.getTime(), data: this.state.menu.data });
            let menu = this.state.menu.data[time] || {};
            let item = { date: d };
            if (Object.keys(menu).length) {
                if (lngId in menu) {
                    item = menu[lngId];
                } else {
                    item = menu[Object.keys(menu)[0]];
                }
            }
            // console.log('LunchMenu.render 2', { time, item: this.state.menu.data[time] });
            item.time = time;

            let day = d.getDay();
            let isWeekend = day === 0 || day === 6;
            item.canEdit = isDinerSelected && !isWeekend && billingInfoIsOk;

            let order = this.state.orders.data[time] || {};
            item.menu1Value = order.menu === 1;
            item.menu2Value = order.menu === 2;
            item.menu3Value = order.menu === 3;
            item.snackValue = order.snack === true;

            if (item.canEdit && time > this.timeLimit) {
                if (item.menu1) {
                    columns[MT_MENU1].canCheck = true;
                    columns[MT_MENU1].times.push(time);
                    if (!item.menu1Value) {
                        columns[MT_MENU1].canNotCheck = true;
                    }
                }
                if (item.menu2) {
                    columns[MT_MENU2].canCheck = true;
                    columns[MT_MENU2].times.push(time);
                    if (!item.menu2Value) {
                        columns[MT_MENU2].canNotCheck = true;
                    }
                }
                if (item.menu3) {
                    columns[MT_MENU3].canCheck = true;
                    columns[MT_MENU3].times.push(time);
                    if (!item.menu3Value) {
                        columns[MT_MENU3].canNotCheck = true;
                    }
                }
                if (item.snack) {
                    columns[MT_SNACK].canCheck = true;
                    columns[MT_SNACK].times.push(time);
                    if (!item.snackValue) {
                        columns[MT_SNACK].canNotCheck = true;
                    }
                }
            }

            days.push(item);
        }

        columns[MT_MENU1].checked = columns[MT_MENU1].canCheck && !columns[MT_MENU1].canNotCheck;
        columns[MT_MENU2].checked = columns[MT_MENU2].canCheck && !columns[MT_MENU2].canNotCheck;
        columns[MT_MENU3].checked = columns[MT_MENU3].canCheck && !columns[MT_MENU3].canNotCheck;
        columns[MT_SNACK].checked = columns[MT_SNACK].canCheck && !columns[MT_SNACK].canNotCheck;

        let dinerCount = Object.keys(this.state.diners.data).length;
        let isSomeDiner = dinerCount > 0;
        let isUserDiner = !!this.state.diners.self;
        let showDinerSelector = (isUserDiner ? dinerCount > 1 : isSomeDiner) && billingInfoIsOk;
        // let showDinerSelector = isSomeDiner;
        let diners = [];
        if (isSomeDiner) {
            diners = arraySort(_.map(this.state.diners.data), 'name');
        }
        let paymentId = '';
        let balance = 0;
        if (isDinerSelected) {
            paymentId = selectedDiner.paymentId;
            balance = selectedDiner.balance;
        }
        let paymentAmount = amount - (balance < 0 ? balance : 0);

        let showNoDinerInfo = this.state.diners.loaded && !isSomeDiner && !hideNoDinerInfo;

        let minPeriod = new Date(2019, (8 - 1), 1).getTime();
        let disablePrevPeriod = periodTime <= minPeriod;
        let disableNextPeriod = addDay(period, NEXT_PERIOD_LIMIT).getTime() > new Date().getTime();

        let accountPrefix = '';
        let accountNumber = '';
        let bankCode = '';
        if (settings.loaded && settings.data.accountNumber) {
            let parts = settings.data.accountNumber.split('/');
            let parts2 = parts[0].split('-');
            accountPrefix = parts2[0];
            accountNumber = parts2[1];
            bankCode = parts[1];
        }
        let showQr = settings.loaded && isDinerSelected;
        let newNumber = settings && settings.loaded && settings.data && !!settings.data.newAccountNumber;

        // console.log('LunchMenu.render', { hidePast });

        return (
            <div className="page-container lunch-menu-container">
                {showNoDinerInfo &&
                    <div className="page-info">
                        <div
                            className="close-btn"
                            onClick={() => this.setState({ hideNoDinerInfo: true })}
                        ><i className="fa fa-times"></i></div>
                        {this.context.translate('LunchMenu.NoDiner')}
                    </div>
                }
                {showMissingBillingInfo &&
                    <div className="page-info">
                        <div
                            className="close-btn"
                            onClick={() => this.setState({ hideMissingBillingInfo: true })}
                        ><i className="fa fa-times"></i></div>
                        <span>{this.context.translate('LunchMenu.MissingBillingInfo.Part1')}</span>
                        {/* {this.context.translate('LunchMenu.MissingBillingInfo.Part1').slice(-1) === " " ? <span>&nbsp;</span> : ''} */}
                        <a
                            href="/profile"
                            onClick={e => {
                                e.preventDefault();
                                this.props.push('/profile');
                            }}
                        >{this.context.translate('Header.Profile')}</a>
                        {/* {this.context.translate('LunchMenu.MissingBillingInfo.Part2').slice(0, 1) === " " ? <span>&nbsp;</span> : ''} */}
                        <span>{this.context.translate('LunchMenu.MissingBillingInfo.Part2')}</span>
                    </div>
                }
                <div className="lunch-menu-part">
                    <div className="page-menu lunch-menu-head">
                        <div className="filter lunch-menu-filter">
                            <div className="filter-group">
                                {/* <div className="title">{this.context.translate('Filter.Title')}</div> */}
                                <label>{this.context.translate('Filter.Period')}</label>
                                <Period
                                    period={this.state.period}
                                    disablePrev={disablePrevPeriod}
                                    disableNext={disableNextPeriod}
                                    onChange={p => this.onPeriodChange(p)}
                                />
                            </div>
                            {showDinerSelector &&
                                <>
                                    <div className="filter-group">
                                        <label>{this.context.translate('Filter.Diner')}</label>
                                        <select
                                            className="form-control diner-selector"
                                            name="diner"
                                            value={this.state.diners.selected}
                                            onChange={e => this.selectedDinerChanged(e.target.value)}
                                        >
                                            {diners.map(o => (
                                                <option key={o.id || 0} value={o.id}>{o.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </>
                            }
                            {hidePastEnabled &&
                                <div
                                    className="check-filter"
                                    onClick={() => this.setState({ hidePast: !hidePast }, () => { localStorage.setItem(HIDE_PAST_KEY, !hidePast); })}
                                >
                                    <div className="label">{this.context.translate('LunchMenu.HidePast')}</div>
                                    <input type="checkbox" checked={hidePast} onChange={() => { }} />
                                </div>
                            }
                        </div>
                        {showDinerInfo &&
                            <>
                                <div className="delimiter"></div>
                                <div className="lunch-menu-info">
                                    <div className="row balance">
                                        <div className="label">{this.context.translate('LunchMenu.Balance')}</div>
                                        <div className="value">{balance} {this.context.translate('Units.Money')}</div>
                                    </div>
                                    {!isAllInclusiveDiner &&
                                        <div className="row other">
                                            <div className="label">{this.context.translate('LunchMenu.UnpostedOrders')}</div>
                                            <div className="value">{amount} {this.context.translate('Units.Money')}</div>
                                        </div>
                                    }
                                    <div className="row other">
                                        <button
                                            className="link-button"
                                            onClick={() => this.setState({ modal: { ...this.state.modal, open: true } })}
                                        >{this.context.translate('LunchMenu.PaymentInfo')}</button>
                                    </div>
                                </div>
                            </>
                        }
                        <div className="delimiter"></div>
                        <div className="buttons">
                            <div
                                className="btn btn-imsp"
                                onClick={() => this.export()}
                            >{this.context.translate('LunchMenu.ExportMenu')}</div>
                        </div>
                    </div>
                    <div className="page-content lunch-table">
                        <table>
                            <colgroup>
                                <col className="date-col" />
                                <col className="menu-col" />
                                <col className="menu-col" />
                                <col className="menu-col" />
                                {menu3Enabled &&
                                    <col className="menu-col" />
                                }
                                <col className="menu-col" />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th><div className="menu-header"><div className="menu-header-title">
                                        {this.context.translate('Lunch.ColDate')}
                                    </div></div></th>
                                    <th><div className="menu-header"><div className="menu-header-title">
                                        {this.context.translate('MealType.0')}
                                    </div></div></th>
                                    {this.renderMealHeader(columns, MT_MENU1)}
                                    {this.renderMealHeader(columns, MT_MENU2)}
                                    {menu3Enabled ? this.renderMealHeader(columns, MT_MENU3) : <></>}
                                    {this.renderMealHeader(columns, MT_SNACK)}
                                </tr>
                            </thead>
                            <tbody>
                                {days.map(o => this.renderRow(o, menu3Enabled))}
                            </tbody>
                        </table>
                    </div>
                </div>

                {this.state.modal.open &&
                    <Modal
                        modalClass="payment-info-modal"
                        onClose={() => this.closeModal()}
                        onSubmit={() => this.closeModal()}
                        title={this.context.translate('LunchMenu.Modal.Title')}
                        show={true}
                        closeOnClick={true}
                    >
                        <div className="payment-info-modal-content">
                            <div className="payment-info">
                                <div className="text-part">
                                    <div className={classnames('payment-row', { 'new-number': newNumber })}>
                                        <div className="label">{this.context.translate('LunchMenu.Modal.Number')}</div>
                                        <div className="value">{this.state.settings.data.accountNumber}</div>
                                    </div>
                                    {newNumber &&
                                        <div className="new-number-info">{this.context.translate('LunchMenu.Modal.NewNumber')}</div>
                                    }
                                    <div className="payment-row">
                                        <div className="label">{this.context.translate('LunchMenu.Modal.Symbol')}</div>
                                        <div className="value">{paymentId}</div>
                                    </div>
                                    <div className="payment-row">
                                        <div className="label">{this.context.translate('LunchMenu.Modal.Ss')}</div>
                                        <div className="value">{this.state.settings.data.specificSymbol}</div>
                                    </div>
                                    <div className="payment-row">
                                        <div className="label">{this.context.translate('LunchMenu.Modal.Amount')}</div>
                                        <div className="value">{paymentAmount} {this.context.translate('Units.Money')}</div>
                                    </div>
                                </div>
                                {showQr &&
                                    <div className="qr-part">
                                        <img
                                            className="qr-img"
                                            alt="qr"
                                            src={`http://api.paylibo.com/paylibo/generator/czech/image?accountPrefix=${accountPrefix}&accountNumber=${accountNumber}&bankCode=${bankCode}&amount=${paymentAmount}&currency=CZK&vs=${paymentId}&ss=${settings.data.specificSymbol}&message=${selectedDiner.name}&branding=false`}
                                        />
                                    </div>
                                }
                            </div>
                            {/* <div className="note">
                                <p className="common-text">{this.context.translate('LunchMenu.Modal.Note')}</p>
                            </div> */}
                            <div className="buttons-row">
                                <button onClick={() => this.closeModal()}>{this.context.translate('LunchMenu.Modal.Close')}</button>
                            </div>
                        </div>
                    </Modal>
                }
            </div>
        );
    }
};

export default connect(
    state => ({
        profile: state.profile,
        language: state.localize.language,
        location: state.router.location
    }), {
    push
})(LunchMenu);