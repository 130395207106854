import {
	SET_LANGUAGE
} from '../stores/localizeStore';

export function setLanguage(language) {
	return (dispatch, getState) => {
		dispatch({
			type: SET_LANGUAGE,
			oldLanguage: getState().localize.language,
			newLanguage: language
		});
	}
}
