import i18next from 'i18next';

import csTranslation from './cs';
import enTranslation from './en';


export default function init(language){
	let cs = csTranslation;
	let en = enTranslation;
	
	i18next.init({
		lng: language || window.language,
		resources: {
			cs: {
				translation: cs
			},
			en: {
				translation: en
			}
		}
	});

	return i18next;
}