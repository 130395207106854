import _ from 'lodash';


export const hasClass = (classesString, classToFind) => {
	classesString = classesString.replace(/\s{2,100}/g, ' ');
	classesString = classesString.trim();

	let classes = [];

	if (classesString.length) {
		classes = classesString.split(' ');
	}

	classes = classes.filter((className) => {
		if (className === classToFind) {
			return true;
		}
		return false;
	});

	if (classes.length > 0) {
		return true;
	}

	return false;
}

export const addClass = (classesString, classToAdd) => {
	classesString = classesString.replace(/\s{2,100}/g, ' ');
	classesString = classesString.trim();

	let classes = [];

	if (classesString.length) {
		classes = classesString.split(' ');
	}

	classes.push(classToAdd);

	classes = _.uniq(classes);

	return classes.join(' ');
}

export const removeClass = (classesString, classToRemove) => {
	classesString = classesString.replace(/\s{2,100}/g, ' ');
	classesString = classesString.trim();

	let classes = [];

	if (classesString.length) {
		classes = classesString.split(' ');
	}

	classes = classes.filter((className) => {
		if (className !== classToRemove) {
			return true;
		}
		return false;
	});

	classes = _.uniq(classes);

	return classes.join(' ');
}