import { LOGOUT } from './authStore';


export const PROFILE_PENDING = 'PROFILE_PENDING';
export const PROFILE_LOADED = 'PROFILE_LOADED';
export const PROFILE_ERROR = 'PROFILE_ERROR';

const initialState = {
	data: {},
	pending: false,
	loaded: false,
	error: null
};

export default function reducer(state = initialState, action = {}) {
	switch (action.type) {
		case PROFILE_PENDING:
			return { ...state, data: {}, pending: true, loaded: false, error: null };

		case PROFILE_LOADED:
			return { ...state, data: action.data, pending: false, loaded: true };

		case PROFILE_ERROR:
			return { ...state, pending: false, loaded: false, error: action.error };

		case LOGOUT:
			// clearBrowserStorage();
			return initialState;

		default:
			return state;
	}
}