import { Log } from 'oidc-client';
import userManager from '../utils/userManager';


export class AuthService {

    constructor() {
        this.userManager = userManager;
        // console.log('settings', { settings });
        Log.logger = console;
        Log.level = Log.INFO;
    }

    getUser() {
        return this.userManager.getUser();
    }

    login() {
        return this.userManager.signinRedirect();
    }

    renewToken() {
        return this.userManager.signinSilent();
    }

    logout() {
        return this.userManager.signoutRedirect();
    }
}
